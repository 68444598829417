(function ($) {
  $('#contactform').submit(function (event) {
    event.preventDefault(); // Prevent direct form submission
    var fieldlist = $('#contactform').find('input, textarea');
    if ($(fieldlist).hasClass('is-invalid')) {
      $(fieldlist).removeClass('is-invalid').siblings('small').remove();
    }

    var resolve = function () {
      $.ajax({
        url: 'resolvecontact',
        type: 'post',
        data: $('#contactform').serialize(),
        dataType: 'json',
        success: function (_response) {
          // The Ajax request is a success. _response is a JSON object
          // console.log('Response', _response);  
          var success = _response.success;

          if (success != '') {
            $('#contactform').remove();
            $('#alert').html(success).removeClass('d-none').fadeIn();
          }
        },
        error: function (jqXhr, json, errorThrown) {
          // In case of Ajax error too, display the result for demo purpose
          var error = jqXhr.responseJSON;

          // console.log(jqXhr);
          // console.log(json);
          // console.log(errorThrown);
          

          $.each(error, function (item, val) {
            var errors = Array.isArray(val) ? val.join('<br>') : val;
            // console.log(item, val.join('<br>'));
            $('#' + item).addClass('is-invalid');
            $('#' + item).parent().append('<small class="text-danger pt-1">' + errors + '</small>');
          });
        }
      });
    }

    grecaptcha.ready(function () {
      grecaptcha.execute('6LfxdQIaAAAAAG9QKgsZiClFdUwJHm4ST8gjrpp6', {
        action: 'contactform'
      }).then(function (token) {
        // console.log('GToken:', token);
        $('#token').val(token);
        resolve();
      });
    });


  });

})(jQuery);
