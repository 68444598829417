(function ($) {
  if ($.fancybox) {
    $.fancybox.defaults.transitionEffect = "fade";
    $.fancybox.defaults.transitionDuration = "1500";

    $("[data-fancybox]").fancybox({
      loop: false,
      infobar: true,
      buttons: ["close"],
      infobar: true,
      zzom: false,
      caption: function () {
        return (
          $(this)
          .find(".caption")
          .html() || ""
        );
      }
    });
  }

  $('#header .toggler').click(function () {
    $(this).toggleClass('is-active');
    $('#header .navbar').toggleClass('show');
    $('body').toggleClass('no-scroll');
    $('body').toggleClass('show-menu');
  });

  /*=============================================
  =            Slider Gallery            =
  =============================================*/


  var slider_gallery = new Swiper('section.slider .swiper-container', {
    // Optional parameters
    // slideClass: 'views-row',
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    delay: 5000,
    speed: 500,
    autoHeight: false,
    zoom: false,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    slidesPerView: 1,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      300: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      // when window width is >= 480px
      800: {
        spaceBetween: 20,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
    }
  });

  /*=============================================
  =            Close Alert            =
  =============================================*/

  var hostname = window.location.hostname;
  var hasAlert = Cookies.get('cookies.alert');

  if (!hasAlert) {
    $('.away').removeClass('d-none');
    $('.away .alert').addClass('fade').addClass('show');

  }

  $('.away .alert').on('closed.bs.alert', function () {
    Cookies.set('cookies.alert', '1', {
      domain: hostname,
      expires: 1 / 24
    });
  });



  /*=============================================
  =            Tiles            =
  =============================================*/

  $('.tiles').on('click', '.tile', function (e) {

    if ($(this).children('.content').length) {
      // $(this).children('.content').toggleClass('d-none');
      console.log($(this).children('.content'));
    }
  })



  /*=============================================
  =            fixed scroll header            =
  =============================================*/
  $(window).scroll(function () {
    if ($(window).scrollTop() >= $('#header').height()) {
      $('#header').addClass('fixed-header');
    }
    if ($(window).scrollTop() == 0) {
      $('#header').removeClass('fixed-header');
    }
  });


  function findBootstrapEnvironment() {
    var envs = ['xs', 'sm', 'md', 'lg', 'xl', 'lx', 'lm'];

    var el = document.createElement('div');
    document.body.appendChild(el);

    var curEnv = envs.shift();

    for (i in envs.reverse()) {
      el.classList.add('d-' + envs[i] + '-none');

      if (window.getComputedStyle(el).display === 'none') {
        curEnv = envs[i];
        break;
      }
    };

    document.body.removeChild(el);
    $('html').addClass('bs-' + curEnv);
    return curEnv;
  }

  // findBootstrapEnvironment();

  /*=============================================
  =            FitVids            =
  =============================================*/

  $(document).ready(function () {
    // Target your .container, .wrapper, .post, etc.
    $(".video-wrapper").fitVids();
  });


  /*=============================================
  =      External Links in new Window           =
  =============================================*/

  $('a[href^="http://"], a[href^="https://"]')
    .not('[href*="' + window.location.hostname + '"]')
    .attr("target", "_blank");

})(jQuery);
